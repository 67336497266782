import * as React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Header from '../components/Header';
import NotFound from '../components/404';

const NotFoundPage = () => (
  <Layout>
    <SEO pageTitle="Page Not Found" description="" />
    <Header background={false} light={true} />
    <NotFound />
  </Layout>
);

export default NotFoundPage;
